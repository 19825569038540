
  import {defineComponent, computed} from "vue"
  import {useModelWrapper} from "@/utils/modelWrapper"

  export default defineComponent({
    model: {
      prop: 'elementValue',
      event: 'update:elementValue'
    },
    props: {
      schema: {
        type: Object,
        required: true
      },
      elementValue: {
        type: Array
      }
    },
    setup(props, { emit }) {
      const checkedValue = useModelWrapper(props, emit, 'elementValue')

      return {
        checkedValue
      }
    }
  })
