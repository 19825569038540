import { render, staticRenderFns } from "./RadioButtonElement.vue?vue&type=template&id=88cf0a00&scoped=true&lang=pug"
import script from "./RadioButtonElement.vue?vue&type=script&lang=ts"
export * from "./RadioButtonElement.vue?vue&type=script&lang=ts"
import style0 from "./RadioButtonElement.vue?vue&type=style&index=0&id=88cf0a00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88cf0a00",
  null
  
)

export default component.exports